import React, { useMemo } from "react";
import { Box, Stack, Typography } from "@mui/material";

import { useGetOrganizationGroups } from "../../hooks/useGetOrganizationGroups";
import { useSelectedOrganization } from "../../hooks/useSelectedOrganization";

interface Props {
  name?: string;
  application?: string;
}

const OrganizationGroupPermissions: React.FC<Props> = ({
  name,
  application,
}) => {
  const { selectedOrganizationId } = useSelectedOrganization();

  const { groups } = useGetOrganizationGroups({
    organizationId: selectedOrganizationId ?? "",
    params: {
      outputRoles: false,
      outputPermissions: false,
    },
  });

  const selectGroupData = useMemo(() => {
    return groups.find((g) => {
      return g.name === name && g.application === application;
    });
  }, [groups, name, application]);

  return (
    <Box>
      {selectGroupData?.permissions?.map((permission) => {
        return (
          <Stack
            direction="row"
            key={permission}
            sx={{
              my: "10px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "7px",
                height: "7px",
                backgroundColor: "#2B76E2",
                borderRadius: "30px",
              }}
            />
            <Typography ml="5px">{permission}</Typography>
          </Stack>
        );
      })}
    </Box>
  );
};

export default OrganizationGroupPermissions;